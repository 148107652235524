import React from "react"
import { Link } from "gatsby"
import { LazyLoadImage } from "react-lazy-load-image-component"
import Layout from "../components/layout"
import "../components/layout.css"
import SEO from "../components/seo"

import morale from "../images/morale.jpg"
import healthcare from "../images/healthcare.png"
import support from "../images/morale-support.jpg"

const HealthcareLeadership = () => (
  <Layout>
    <SEO
      title="Healthcare Leadership Development Course - Healthcare Executive Coaching"
      description="Here at AGL we take pride in providing healthcare leadership executive development coaching courses to improve your morale. We want to help you, contact us today."
    />
    <div className="page-headline">
      <div className="container ">
        <div className="section-heading text-center">
          <h1>
            <strong>Healthcare Leadership Development Courses</strong>
          </h1>
        </div>
        <div className="my-5 text-center">
          <Link to="/#get-coaching">
            <span className="hero-btn2 align-items-center sched-modal">
              Get Coaching
            </span>
          </Link>
        </div>
      </div>
      <div className="headline-bg" />
    </div>
    <div className="page-content">
      <div className="container">
        <div className="row justify-content-between pt-5">
          <div className="col-md-3 mx-auto text-center">
            <LazyLoadImage
              className="engager-icon mr-3 img-fluid mb-5"
              src={healthcare}
              alt="Healthcare Icon"
            />
          </div>
          <div className="col-md-8 mx-auto">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-md-12">
                  <h2 className="mb-0">
                    <strong>
                      Importance of Morale in the Healthcare Sector
                    </strong>{" "}
                  </h2>
                  <p className="">
                    Organizational culture is often disregarded in favor of more
                    technical aspects that may be perfectly valid, just not
                    all-encompassing. Building a strong culture within your
                    company is especially important in the healthcare sector, as
                    we will show further on and healthcare executive coaching
                    and healthcare leadership development courses can help
                    improve overall morale.
                  </p>
                  <p className="">
                    Healthcare has been under fire for the past two years.
                    Nurses, doctors, techs, and more have been stretched to the
                    breaking point, then asked to keep going.
                  </p>
                  <p className="">
                    As our coaches have supported healthcare organizations,
                    through healthcare leadership development courses, we have
                    learned the critical importance of building strong morale
                    and a positive organizational culture, especially in
                    difficult seasons.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="page-content healthcare-bg">
      <div className="container">
        <div className="row justify-content-between pt-5 p-2  text-white">
          <div className="col-md-6 mx-auto">
            <h2 className="mb-0	text-white">
              <strong>
                Companies in the healthcare industry should pay attention to how
                their employees perceive the internal culture of the company.
              </strong>{" "}
            </h2>
            {/* <ol className=" text-left text-white">
              <li className=" my-3">
                It’s about INVESTing. Invest in yourself. Invest in your
                leadership potential. Invest in your team and your organization.
                Things don’t grow without investment, and nothing invested is
                ever wasted.
              </li>
              <li className=" my-3">
                It’s about VESTing. The word “vest” means to confer or bestow
                authority. Sometimes, we are just looking for someone outside of
                ourselves to give us a little validation. To let us know that
                we’re on the right track, and give us an idea of what to do
                next.
              </li>
              <li className=" my-3">
                It’s about LEADERvesting. Not just investing some dollars in
                training. Not just receiving someone else’s validation. It’s
                about finding the leader in yourself, calling out your own
                authority and power, understanding your unique values and
                knowing how they show up in everything you do. It’s choosing to
                claim, confer and bestow your own authority for yourself and
                this leadership training program does all the above and more.
              </li>
            </ol> */}
            <p className="text-white">
              Having a poor performance in this regard has direct consequences
              on your performance and achievements.{" "}
            </p>
            <p className="text-white">
              Building a positive organizational culture may be important, but
              what exactly is it?? The simplest way to define organizational
              culture is “how a company does things”. In other words, what kind
              of values do you emphasize, how do you reward employees, what kind
              of leadership do you promote? Often, company founders or managers
              don’t have a good grasp of the culture, and that will echo further
              on. Contact us to learn how our healthcare leadership development
              course can help.
            </p>
          </div>
          <div className="col-md-6 mx-auto"></div>
        </div>
      </div>
    </div>
    <div className="page-content">
      {/*<div className="col-md-3 mx-auto">
						<LazyLoadImage
							className="engager-icon mr-3 img-fluid"
							src={support}
							alt="Engager Icon"
						/>
					</div>*/}

      <div className="container">
        <div className="row justify-content-between pt-5">
          <div className="col-md-6">
            <h2 className="mb-0 text-gold">
              <strong>
                What Happens When Culture and Leadership Are Not Working?
              </strong>{" "}
            </h2>
            <p className="">
              There are costly and far-reaching impacts when the culture of a
              healthcare organization is failing.
            </p>
            <div className="mx-auto">
              <LazyLoadImage
                className="mr-3 mb-3 img-fluid"
                src={support}
                alt="Engager Icon"
              />
            </div>
          </div>
          <div className="col-md-6">
            <p className="">
              Perhaps the biggest factor influencing the well-being of
              healthcare employees is stress. With jobs that often carry huge
              stakes, medical care employees feel the effects of long-term
              stress on their own health. This stress impacts a healthcare
              worker's performance, satisfaction in the workplace, and
              willingness to stay at a job. It’s a vicious cycle of
              stress-induced chain of issues.
            </p>
            <p className="">
              One of the biggest effects of unsatisfied employees in healthcare
              is turnover. Employees will leave often for another job they
              perceive as being less stressful, more fulfilling.
            </p>
            <p className="">
              Stats show that the turnover of one primary care physician causes{" "}
              <a href="http://www.pr.com/press-release/720863" target="_blank">
                losses of $1.3 million
              </a>
              , while a nurse leaves{" "}
              <a
                href="http://www.nsinursingsolutions.com/Files/assets/library/retention-institute/NationalHealthcareRNRetentionReport2016.pdf"
                target="_blank"
              >
                a hole of $58,000.{" "}
              </a>
              Not only that but the dynamics of entire teams are affected.
            </p>

            <p className="">
              If there is no unitary sense of purpose throughout your
              organization, people won’t work tightly as teams and won’t be
              ready to share their expertise with their colleagues. In the
              healthcare sector, not collaborating successfully can influence
              the medical act and even the mortality rate of a unit. For
              example, doctors and nurses should have a positively reinforced
              interaction and a seamless collaboration. Healthcare leadership
              development courses help you bring your organization closer
              together to truly thrive.
            </p>
          </div>
        </div>
      </div>
    </div>

    <div className="page-content morale-bg">
      <div className="container">
        <div className="row justify-content-between pt-5 p-2">
          <div className="col-md-6 mx-auto">
            <h2 className="mb-0	text-white">
              <strong>
                What Can Healthcare Executive Coaching Do for Your Organization?
              </strong>{" "}
            </h2>
            <p className="text-white ">
              As we have seen the damaging impact of lack of communication,
              employee distrust, lack of teamwork, and negative feelings in the
              workplace, we have identified key employee development tools to
              build a positive organizational culture in healthcare companies.
            </p>
            <p className="text-white ">
              An initiative that supports a strongly built culture, like a{" "}
              <b>healthcare leadership development course</b>, supports better
              results. Coaching goes through your organization’s entire work
              process, identifying those weak points where people seem to lose
              focus, motivation, or performance.
            </p>
            <p className="text-white ">
              In the healthcare sector, to have disengaged employees can mean a
              big blow to your entire unit’s performance, your advancement,
              patient recovery, and patient satisfaction. Create a tight bond
              between your employees and a high level of trust from top to
              bottom by getting assessed by a coach. From assessing your
              micromanagement techniques to going to the core values and
              missions of the entire organization, we at AGL Coaching for Good
              are helping you consolidate your processes brick by brick.
            </p>
            <p className="text-white mb-5 ">
              Through healthcare executive coaching, we aim to improve the
              medical act itself and be of better use to society as a whole.
            </p>
            {/* <Link to="/contact-us">
              <span className="schedule-btn2 top-btn-sched mr-3 sched-modal mt-5">
                &nbsp;Contact Us
              </span>
            </Link> */}
          </div>
          <div className="col-md-6 mx-auto"></div>
        </div>
      </div>
    </div>
    <div className="page-content ">
      <div className="container">
        <div className="row justify-content-between pt-5">
          <div className="col-md-6 mx-auto">
            <div className="">
              <h2 className="mb-0 ">
                <strong>Morale in the Healthcare Sector</strong>{" "}
              </h2>
              <p className=" ">
                So what's the bottom line? If you are able to raise the levels
                of confidence in co-workers, superiors, supportive staff
                members, and so on, you will encourage medical specialists to
                give their best. This, naturally, will raise the bar overall.
                Support good morale in your medical unit by bringing self-care
                and the care for patients in the first line.
              </p>
              <p>
                Not feeding the competitiveness that exists in the healthcare
                industry might be a challenge. With the help of healthcare
                executive coaching, you can define your goals more precisely and
                find the best ways to reflect them on your staff.
              </p>
              <p>
                Contact AGL <Link to="/contact-us">Coaching for Good</Link> to
                find out how you can improve the performance of your medical
                unit by simply creating a strong internal culture, offering
                incentives that people respond to, and showing the results that
                motivate your employees.{" "}
              </p>
              <div className="my-5 text-center">
                <Link to="/#get-coaching">
                  <span className="hero-btn align-items-center sched-modal">
                    Get Coaching
                  </span>
                </Link>
              </div>
            </div>
          </div>
          <div className="col-md-6 mx-auto">
            <div className="mx-auto">
              <LazyLoadImage
                className="mr-3 mb-3 mt-3 img-fluid"
                src={morale}
                alt="Morale"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default HealthcareLeadership
